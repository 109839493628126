<div class="modal-header">
  <h4 class="modal-title">Delete {{item}}</h4>
</div>
<div class="modal-body text-center mt-3">
	<p>Are you sure you want to delete this {{item}}?</p>
	<p><strong>{{message}}</strong></p>
</div>
<div class="modal-footer"  style="color:black;">
	<button type="button" class="btn btn-sm btn-primary" (click)="confirm()">Delete</button>
  	<button type="button" class="btn btn-sm btn-secondary" (click)="cancel()">Cancel</button>
</div>