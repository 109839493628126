import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { environment } from '@environment';
import { SessionService } from '../services/session.service';

export const Permissions = {
  PERMISSION_TRANSACTION_UPDATE: 'TRANSACTION_UPDATE',
  PERMISSION_TRANSACTION_CREATE: 'TRANSACTION_CREATE',
  PERMISSION_PLUGIN_CREATE: 'MERCHANT_PLUGIN_CREATE',
  PERMISSION_PLUGIN_UPDATE: 'MERCHANT_PLUGIN_UPDATE',
  PERMISSION_TRANSACTION_STATUSES_READ: 'TRANSACTION_STATUSES_READ',
  PERMISSION_TRANSACTION_READ: 'TRANSACTION_READ'
}

export const PermissionScopes = {
  SYSTEM_SCOPE: 'SYSTEM',
  COMPANY_SCOPE: 'COMPANY'
}

@Injectable()
export class PermissionService {

  constructor(private http: HttpClient, private sessionService: SessionService) {}

  getPermissions(token) {
    const headers = new HttpHeaders({'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization' : 'Bearer ' + token});

    return this.http.get(environment.connexusAPI + "/securities/permissions", {headers});
  }
}
