import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { environment } from '@environment';
import { SessionService } from './session.service';

@Injectable()
export class TransactionProviderTemplatesService {

  constructor(private http: HttpClient, private sessionService: SessionService) {}

  createAuthHeader(token) {
    return new HttpHeaders({'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization' : 'Bearer ' + token});
  }

  createTemplate(token, data) {
    let headers = this.createAuthHeader(token);

    return this.http.post(environment.connexusAPI + '/transaction-provider-templates', data, {headers});
  }

  getTemplates(token, type?, active?) {
    let headers = this.createAuthHeader(token);
    let urlSuffix = '/transaction-provider-templates?';

    if (type)
      urlSuffix += '&type=' + type;

    if (active)
      urlSuffix += '&active=' + active;

    return this.http.get(environment.connexusAPI + urlSuffix, {headers});
  }

  getTemplate(token, templateId) {
    let headers = this.createAuthHeader(token);

    return this.http.get(environment.connexusAPI + '/transaction-provider-templates/' + templateId, {headers});
  }

  updateTemplate(token, data) {
    let headers = this.createAuthHeader(token);

    return this.http.put(environment.connexusAPI + '/transaction-provider-templates/'+ data.id, data, {headers});
  }
}
