import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { environment } from '@environment';
import { SessionService } from './session.service';

@Injectable()
export class PersonService {

  constructor(private http: HttpClient, private sessionService: SessionService) { }

  createAuthHeader(token) {
    return new HttpHeaders({'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization' : 'Bearer ' + token});
  }

  getPersons(token, active?, companyId?) {
    let headers = this.createAuthHeader(token);
    let requestUrl = environment.connexusAPI + '/persons';

    if (active && companyId) {
      requestUrl = requestUrl + '?active=' + active + '&company-id=' + companyId;
    } else if (active) {
      requestUrl = requestUrl + '?active=' + active;
    } else if (companyId) {
      requestUrl = requestUrl + '?company-id=' + companyId;;
    }

    return this.http.get(requestUrl, { headers });
  }

  getPerson(token, id) {
    let headers = this.createAuthHeader(token);

    return this.http.get(environment.connexusAPI + '/persons/' + id, {headers});
  }

  createPerson(token, body) {
    let headers = this.createAuthHeader(token);
    body = JSON.stringify(body);

    return this.http.post(environment.connexusAPI + '/persons', body, {headers});
  }

  updatePerson(token, id, body) {
    let headers = this.createAuthHeader(token);
    body = JSON.stringify(body);

    return this.http.put(environment.connexusAPI + '/persons/' + id, body, {headers});

  }
}
