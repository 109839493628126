import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { environment } from '@environment';
import { SessionService } from '../services/session.service';

@Injectable()
export class FinancialInstitutionService {

  constructor(private http: HttpClient, private sessionService: SessionService) {}

  getFinancialInstitutions(lookUp, token) {
    const headers = new HttpHeaders({'Content-Type': 'application/json', 'Accept': 'application/vnd.connexus.v2+json', 'Authorization' : 'Bearer ' + token});
    let requestUrl = environment.connexusAPI + '/bridge/financial-institutions';

    if(lookUp)
      requestUrl = requestUrl + '?like=' + encodeURIComponent(lookUp);

    return this.http.get(requestUrl, {headers});
  }

  getFinancialInstitutionsList(token) {
    const headers = new HttpHeaders({'Content-Type': 'application/json', 'Accept': 'application/vnd.connexus.v2+json', 'Authorization' : 'Bearer ' + token});

    return this.http.get(environment.connexusAPI + '/financial-institutions', {headers});
  }

  getFinancialInstitutionTypes(token) {
    const headers = new HttpHeaders({'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization' : 'Bearer ' + token});

    return this.http.get(environment.connexusAPI + '/financialinstitutions/types', {headers});
  }

  createFinancialInstitution(body, token) {
    const headers = new HttpHeaders({'Content-Type': 'application/json', 'Accept': 'application/vnd.connexus.v2+json', 'Authorization' : 'Bearer ' + token});

    return this.http.post(environment.connexusAPI + '/financial-institutions', body, {headers});
  }

  getFinancialInstitutionById(id, token) {
    const headers = new HttpHeaders({'Content-Type': 'application/json', 'Accept': 'application/vnd.connexus.v2+json', 'Authorization' : 'Bearer ' + token});

    return this.http.get(environment.connexusAPI + '/financial-institutions/' + id, {headers});
  }

  updateFinancialInstitution(id, body, token) {
    const headers = new HttpHeaders({'Content-Type': 'application/json', 'Accept': 'application/vnd.connexus.v2+json', 'Authorization' : 'Bearer ' + token});

    return this.http.put(environment.connexusAPI + '/financial-institutions/' + id, body, {headers});
  }

}

