import { tap, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable, Subject }  from 'rxjs';

import { environment } from '@environment';
import { SessionService } from '../services/session.service';

export const TransactionStatusIds = {
  QUEUED: 1,
  PROCESSED: 3,
  CANCELLED: 5,
  RETURNED: 6,
}

@Injectable()
export class TransactionsService {

  private transactionMetricCall = new Subject<any>();
  public getTransactionStatObject = this.transactionMetricCall.asObservable();

  private queuedTransactionAmountCall = new Subject<any>();
  public getQueuedTransactionAmount = this.queuedTransactionAmountCall.asObservable();

  private processingTransactionAmountCall = new Subject<any>();
  public getProcessingTransactionAmount = this.processingTransactionAmountCall.asObservable();

  private processedTransactionAmountCall = new Subject<any>();
  public getProcessedTransactionAmount = this.processedTransactionAmountCall.asObservable();

  private cancelledTransactionAmountCall = new Subject<any>();
  public getCancelledTransactionAmount = this.cancelledTransactionAmountCall.asObservable();

  private errorTransactionAmountCall = new Subject<any>();
  public getErrorTransactionAmount = this.errorTransactionAmountCall.asObservable();

  private collapseTransactionStatisticsCall = new Subject<any>();
  public collapseTransactionStatistics = this.collapseTransactionStatisticsCall.asObservable();

  private transactionMetrics = {
    queued: null,
    processing: null,
    processed: null,
    error: null,
    cancelled: null
  }

  constructor(private http: HttpClient, private sessionService: SessionService) {}

  getTransactions(token, dateFrom, dateTo, status, companyId, name, routingNumber, accountNumber, companyAcountId?) {
    const headers = new HttpHeaders({'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization' : 'Bearer ' + token});

    let urlSuffix = '/transactions?datefrom=' + dateFrom + '&dateto=' + dateTo;
    if (companyId)
      urlSuffix += '&companyid=' + companyId;

    if (status)
      urlSuffix = urlSuffix + '&status=' + status;

    if (name)
      urlSuffix = urlSuffix + '&name=' + name;
    else if (routingNumber && accountNumber)
      urlSuffix = urlSuffix + '&routing-number=' + routingNumber + '&account-number=' + accountNumber;

    if (companyAcountId)
      urlSuffix = urlSuffix + '&company-account-id=' + companyAcountId;

    return this.http.get(environment.connexusAPI + urlSuffix, {headers}).pipe(
       tap((res) =>{
        this.sendTransactionObject(res);
      }));
  }

  handleError() { }

  getTransactionById(id, token) {
    const headers = new HttpHeaders({'Content-Type': 'application/json', 'Accept': 'application/vnd.connexus.v2+json', 'Authorization' : 'Bearer ' + token});

    return this.http.get(environment.connexusAPI + '/transactions/' + id, {headers});
  }

  updateTransactionById(transactionBody, token) {
    const headers = new HttpHeaders({'Content-Type': 'application/json', 'Accept': 'application/vnd.connexus.v2+json', 'Authorization' : 'Bearer ' + token});
    let body = JSON.stringify(transactionBody);

    return this.http.put(environment.connexusAPI + '/transactions/' + transactionBody.id, body, {headers});
  }

  createTransaction(bodyObj, token): Observable<any | HttpErrorResponse> {
    const headers = new HttpHeaders({'Content-Type': 'application/json', 'Accept': 'application/vnd.connexus.v2+json', 'Authorization' : 'Bearer ' + token});
    const body = JSON.stringify(bodyObj);

    return this.http.post(environment.connexusAPI + '/transactions', body, {headers});
  }

  verifyPaymentInformation(body, token) {
    const headers = new HttpHeaders({'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization' : 'Bearer ' + token});

    return this.http.post(environment.connexusAPI + '/verifications', body, {headers});
  }

  sendTransactionObject(result) {
    let queuedTransactions = this.hasStatusObjects('Queued', result);
    let processingTransactions = this.hasStatusObjects('Processing', result);
    let processedTransactions = this.hasStatusObjects('Processed', result);
    let errorTransactions = this.hasStatusObjects('Error', result);
    let cancelledTransactions = this.hasStatusObjects('Cancelled', result);

    let queuedAmount = Math.round(this.calculateTransactionAmount('Queued', result) * 100)/100;
    let processingAmount = Math.round(this.calculateTransactionAmount('Processing', result) * 100)/100;
    let processedAmount = Math.round(this.calculateTransactionAmount('Processed', result) * 100)/100;
    let errorAmount = Math.round(this.calculateTransactionAmount('Error', result) * 100)/100;
    let cancelledAmount = Math.round(this.calculateTransactionAmount('Cancelled', result) * 100)/100;

    this.transactionMetrics.queued = queuedTransactions.length;
    this.transactionMetrics.processing = processingTransactions.length;
    this.transactionMetrics.processed = processedTransactions.length;
    this.transactionMetrics.error = errorTransactions.length;
    this.transactionMetrics.cancelled = cancelledTransactions.length;

    this.transactionMetricCall.next(this.transactionMetrics);
    this.errorTransactionAmountCall.next(errorAmount);
    this.cancelledTransactionAmountCall.next(cancelledAmount);
    this.processedTransactionAmountCall.next(processedAmount);
    this.processingTransactionAmountCall.next(processingAmount);
    this.queuedTransactionAmountCall.next(queuedAmount);
  }

  hasStatusObjects(status, transactions) {
    let objects: number[] = [];

    for (var i=0; i < transactions.length; i++) {
      if (transactions[i].transactionStatusName == status) {
        objects.push(i);
      }
    }
    return objects;
  }

  calculateTransactionAmount(status, transactions) {
    let amount = 0;

    for (var i=0; i < transactions.length; i++) {
      if (transactions[i].transactionStatusName == status) {
        amount += transactions[i].amount;
      }
    }
    return amount;
  }

  collapseTransactionPieChart() {
    this.collapseTransactionStatisticsCall.next();
  }

  getTransactionStatuses(token) {
    const headers = new HttpHeaders({'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization' : 'Bearer ' + token})

    return this.http.get(environment.connexusAPI + '/transactionstatuses', { headers });
  }

  uploadFile(transactionId, formData, token) {
    const headers = new HttpHeaders({'Accept': 'application/json', 'Authorization' : 'Bearer ' + token})

    return this.http.post<any>(environment.connexusAPI + '/transactions/'+ transactionId +'/files', formData, { headers }).pipe(
      map((res) =>{

        let responseContent = res;
        responseContent.url = environment.connexusAPI + '/' + responseContent.url;
        return responseContent;
      }));
  }
}
