import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '@environment';
import { SessionService } from '../services/session.service';

@Injectable()
export class RolesService {

  constructor(private http: HttpClient, private sessionService: SessionService) {}

  getAllRoles(token) {
    const headers = new HttpHeaders({'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization' : 'Bearer ' + token})

    return this.http.get(environment.connexusAPI + '/securities/roles', {headers});
  }

  getRole(token, roleId) {
    const headers = new HttpHeaders({'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization' : 'Bearer ' + token})

    return this.http.get(environment.connexusAPI + '/securities/roles/' + roleId, {headers});
  }

  getRolePermissions(token, roleId) {
    const headers = new HttpHeaders({'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization' : 'Bearer ' + token})

    return this.http.get(environment.connexusAPI + '/securities/roles/' + roleId + '/permissions', {headers});
  }

  addRolePermission(token, roleId, permissionId) {
    const headers = new HttpHeaders({'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization' : 'Bearer ' + token})

    return this.http.put(environment.connexusAPI + '/securities/roles/' + roleId + '/permissions/' + permissionId, null, {headers});
  }

  deleteRolePermission(token, roleId, permissionId) {
    const headers = new HttpHeaders({'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization' : 'Bearer ' + token})

    return this.http.delete(environment.connexusAPI + '/securities/roles/' + roleId + '/permissions/' + permissionId, {headers});
  }
}
