<div class="modal-header">
  <h4 class="modal-title">Cancel {{itemTitle}}</h4>
</div>
<div class="modal-body text-center mt-3">
	<p>Are you sure you want to cancel this {{item}}?</p>
	<p><strong>{{message}}</strong></p>
</div>
<div class="modal-footer"  style="color:black;">
	<button type="button" class="btn btn-sm btn-primary" (click)="confirm()">Yes</button>
  	<button type="button" class="btn btn-sm btn-secondary" (click)="cancel()">No</button>
</div>