import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { environment } from '@environment';
import { SessionService } from '../services/session.service';

@Injectable()
export class ApiKeyService {

  constructor(private http: HttpClient, private sessionService: SessionService) {}

  getApiKeys(userId, token) {
    const headers = new HttpHeaders({'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization' : 'Bearer ' + token});

    return this.http.get(environment.connexusAPI + '/users/' + userId + '/apikeys', {headers});
  }

  createApiKey(userId, token) {
    const headers = new HttpHeaders({'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization' : 'Bearer ' + token});

    return this.http.post(environment.connexusAPI + '/users/' + userId + '/apikeys', '{}', {headers});
  }

  updateApiKey(userId, apiKey, enabled, token) {
    const headers = new HttpHeaders({'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization' : 'Bearer ' + token});
    const body = JSON.stringify({enabled});

    return this.http.put(environment.connexusAPI + '/users/' + userId + '/apikeys/' + apiKey, body, {headers});
  }

}
