import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { environment } from '@environment';
import { SessionService } from '../services/session.service';

@Injectable()
export class PayLinkService {

  constructor(private http: HttpClient, private sessionService: SessionService) { }

  createPayLink(token, requestBody) {
    const headers = new HttpHeaders({'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': 'Bearer ' + token});

    requestBody = JSON.stringify(requestBody);

    return this.http.post( environment.connexusAPI + '/pay-links', requestBody, {headers} );
  }
}
