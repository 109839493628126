import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { environment } from '@environment';
import { SessionService } from '../services/session.service';

export const PluginConfigOptions = {
  ADDRESS_VERIFICATION: 'Address Verification',
  AMOUNT_EDITABLE: 'Amount Editable',
  CALLBACK_URL: 'Callback Url',
  SEND_EMAIL: 'Send Email',
  DISABLE_NON_INTEGRATED: 'Show Only Integrated Banks',
  PAYMENT_VALIDATION: 'Payment Validation',
  DISABLE_MANUAL_ACCOUNT_ENTRY : 'Disable Manual Account Entry',
  SHOW_CONFIRMATION_PAGE : 'Show Confirmation Page',
  COMPANY_ID:  'Company ID',
  COMPANY_NAME: 'Company Name',
  ALLOW_BASIC_VERIFICATION_DENY : 'Allow Basic Verification Deny'
}

@Injectable()
export class PluginConfigOptionService {

  constructor( private http: HttpClient, private sessionService: SessionService ) { }

  getPluginConfigOptions(token, typeId) {
    const headers = new HttpHeaders({'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': 'Bearer ' + token});

    return this.http.get(environment.connexusAPI + '/pluginconfigoptions?plugintypeid=' + typeId, {headers});
  }
}
