import { Component, Input, Inject, forwardRef, OnInit, ViewChild, AfterViewChecked, ViewEncapsulation  } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

declare var $:any;

@Component({  
  selector: 'delete-confirmation',
  templateUrl: 'delete-confirmation.component.html',
  encapsulation: ViewEncapsulation.None
})

export class DeleteConfirmationComponent{

  @Input() item;
  @Input() message;

  constructor( public activeModal: NgbActiveModal, private modalService: NgbModal) {}
  
  confirm(): void {
    this.activeModal.close('Confirmed');
  }
  
  cancel(): void {
    this.activeModal.close('Close click');
  }  
}