import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { environment } from '@environment';
import { Router } from '@angular/router';
import { LOGOUT_REASONS } from '../shared/data';

@Injectable()
export class CommonsService {

  private CALLING_SITE_PARAM_START_INDEX = 15;

  constructor(private http: HttpClient, public router: Router, private modalService: NgbModal){}

  getParameterByName(name) {
    let url = window.location.href;

    name = name.replace(/[\[\]]/g, '\\$&');
    let regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
      results = regex.exec(url);

    if (!results) return null;

    if (!results[2]) return '';

    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  }

  validateAmount(amount) {
    amount = amount.replace(',', '');
    if(Number(amount) > 0 && Number(amount) <= 99999.99){
      return true;
    } else {
      return false;
    }
  }

  setCallBackURL() {
    sessionStorage.setItem('Callback Url', this.getCallBackURLParameters() + sessionStorage.getItem('callingSiteParams'));
  }

  getCallBackURLParameters() {
    const callBackURL = sessionStorage.getItem('OriginalCallbackUrl');
    const cxsMerchantTrackingNumber = sessionStorage.getItem('CXSmerchantTrackingNumber');
    const cxsPluginKey = sessionStorage.getItem('CXSpluginKey');
    const cxsAccountToken = sessionStorage.getItem('CXSaccountToken');
    const cxsTransactionStatus =sessionStorage.getItem('CXStransactionStatus');
    const cxsTransactionId = sessionStorage.getItem('CXStransactionId');
    const cxsTransactionDate = sessionStorage.getItem('CXStransactionDate');
    const cxsCutomerName = sessionStorage.getItem('CXScustomerName');
    const cxsCutomerAddress = sessionStorage.getItem('CXScustomerAddress');
    const cxsCutomerCity  = sessionStorage.getItem('CXScustomerCity');
    const cxsCutomerState = sessionStorage.getItem('CXScustomerState');
    const cxsCutomerZipcode = sessionStorage.getItem('CXScustomerZipcode');
    const cxsCutomerEmail   = sessionStorage.getItem('CXScustomerEmail');
    const cxsCustomerPhone = sessionStorage.getItem('CXScustomerPhone');
    const cxsTransactionAmount = sessionStorage.getItem('CXStransactionAmount');

    return callBackURL+'?CXSmerchantTrackingNumber=' + cxsMerchantTrackingNumber
        + '&CXSpluginKey=' + cxsPluginKey
        + '&CXSaccountToken=' + cxsAccountToken
        + '&CXStransactionStatus=' + cxsTransactionStatus
        + '&CXStransactionId=' + cxsTransactionId
        + '&CXStransactionAmount=' + cxsTransactionAmount
        + '&CXStransactionDate=' + cxsTransactionDate
        + '&CXScustomerName=' + cxsCutomerName
        + '&CXScustomerAddress=' + cxsCutomerAddress
        + '&CXScustomerCity=' + cxsCutomerCity
        + '&CXScustomerState=' + cxsCutomerState
        + '&CXScustomerZipcode=' + cxsCutomerZipcode
        + '&CXScustomerEmail='+ cxsCutomerEmail
        + '&CXScustomerPhone=' + cxsCustomerPhone ;
  }

  getCallingSiteParams(){
    let url = window.location.href;
    url = url.split('&').splice(this.CALLING_SITE_PARAM_START_INDEX).join('&');

    if (url.length > 0) {
      return '&' + url;
    }

    return url;
  }

  updateJwtToken() {
    const token = sessionStorage.getItem('auth_token');
    const headers = new HttpHeaders({'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': 'Bearer ' + token});

    return this.http.get(environment.connexusAPI + '/tokens', {headers, observe: 'response'}).pipe(
      map((res) => {
        const authorizationToken = res.headers.get('Authorization');
        const jwtToken = authorizationToken.substring(7);
        sessionStorage.setItem('auth_token', jwtToken);
        return true;
      }));
  }

  sessionLogout(reason) {
    sessionStorage.removeItem('auth_token');
    sessionStorage.removeItem('userName');
    this.modalService.dismissAll();

    switch (reason) {
      case '401':
        this.router.navigate(['/auth/login'], { queryParams: {'reason': LOGOUT_REASONS.SESSION_INVALID}});
        break;
      case '408':
        this.router.navigate(['/auth/login'], { queryParams: {'reason': LOGOUT_REASONS.SESSION_EXPIRED}});
        break;
      default:
        this.router.navigate(['/auth/login'], { queryParams: {'reason': LOGOUT_REASONS.USER_LOGGED_OUT}});
        break;
    }
  }
}
