import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
	{ path: '', redirectTo: '/account-access', pathMatch: 'full' },
	{ 
		path: 'auth',
		loadChildren: () => import('./authentication/authentication.module').then(m => m.AuthenticationModule)
  },	
  { 
		path: 'account-access',
		loadChildren: () => import('./account-access/account-access.module').then(m => m.AccountAccessModule)
  },   
  { path: '**', redirectTo: '/auth/login' }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
    exports: [RouterModule]
})

export class AppRoutingModule { }
