import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '@environment';
import { SessionService } from '../services/session.service';

@Injectable()
export class CompanyProductService {

  constructor(private http: HttpClient, private sessionService: SessionService) {}

  getAllProducts(token) {
    const headers = new HttpHeaders({'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization' : 'Bearer ' + token});

    return this.http.get(environment.connexusAPI + '/products', {headers});
  }

  getCompanyProducts(companyId, token) {
    const headers = new HttpHeaders({'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization' : 'Bearer ' + token});

    return this.http.get(environment.connexusAPI + '/companies/' + companyId + '/products', {headers});
  }

  addCompanyProducts(companyId, productId, token) {
    const headers = new HttpHeaders({'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization' : 'Bearer ' + token});
    const body = JSON.stringify(null);

    return this.http.put(environment.connexusAPI + '/companies/' + companyId + '/products/' + productId, body, {headers});
  }


  deleteCompanyProducts(companyId, productId, token) {
    const headers = new HttpHeaders({'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization' : 'Bearer ' + token});

    return this.http.delete(environment.connexusAPI + '/companies/' + companyId + '/products/' + productId, {headers});
  }

}
