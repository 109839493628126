import { Component, Input, Inject, forwardRef, OnInit, ViewChild, AfterViewChecked, ViewEncapsulation  } from '@angular/core';
import { Router } from '@angular/router';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';

import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { CommonsService } from '@services/commons.service';
import { UserService } from '@services/user.service';

declare var $:any;

@Component({
  selector: 'ngbd-modal-content',
  templateUrl: 'session-modal.component.html',
  encapsulation: ViewEncapsulation.None
})

export class SessionModalComponent{
  public idleState: string = 'Not started.';
  public timedOut: boolean = false;
  private timeOutSubscribe;

  constructor(
    public activeModal: NgbActiveModal,
    public commonsService: CommonsService,
    public router: Router,
    private idle: Idle,
    private modalService: NgbModal
  ) {
    // sets an idle timeout of 0.10 seconds, for testing purposes.
    idle.setIdle(0.10);
    // sets a timeout period of 5 seconds. after 30 seconds of inactivity, the user will be considered timed out.
    idle.setTimeout(30);

    //idle.onIdleEnd.subscribe(() => this.idleState = 'No longer idle.');
    this.timeOutSubscribe = idle.onTimeout.subscribe(() => {
      this.idleState = 'Timed out!';
      this.timedOut = true;
      this.sessionEnd('timeout');
      });

    idle.onTimeoutWarning.subscribe((countdown) => this.idleState = 'You will time out in ' + countdown + ' seconds!');

    this.reset();
  }

  reset(): void {
    this.idle.watch();
    this.idleState = '';
    this.timedOut = false;
  }

  continueSession(): void {
    this.commonsService.updateJwtToken().subscribe((result: any) => {
      if(result) {
        this.timeOutSubscribe.unsubscribe();
        this.activeModal.close('Continue');
      }
    })
  }

  sessionEnd(logoutType): void {
    this.timeOutSubscribe.unsubscribe();
    this.activeModal.close(logoutType);
  }

}
