import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '@environment';
import { SessionService } from '../services/session.service';

@Injectable()
export class AccountBlacklistService {

  constructor(private http: HttpClient, private sessionService: SessionService) {}

  getAccountBlacklist(token, active) {
    const headers = new HttpHeaders({'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': 'Bearer ' + token});
    let urlSuffix = '/account-blacklist';

    if (active != null)
			urlSuffix = urlSuffix + '?active=' + active;

    return this.http.get(environment.connexusAPI + urlSuffix, {headers});
  }

  getAccount(token, accountId) {
    const headers = new HttpHeaders({'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': 'Bearer ' + token});

    return this.http.get(environment.connexusAPI + '/account-blacklist/' + accountId, {headers});
  }

  updateAccount(token, requestBody, accountId) {
    const headers = new HttpHeaders({'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': 'Bearer ' + token });
    requestBody = JSON.stringify(requestBody);

    return this.http.put(environment.connexusAPI + '/account-blacklist/' + accountId, requestBody, {headers});
  }

  createAccount(token, requestBody){
    const headers = new HttpHeaders({'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization' : 'Bearer ' + token});

    return this.http.post(environment.connexusAPI + '/account-blacklist/', requestBody, {headers});
  }

}
