import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { environment } from '@environment';
import { SessionService } from '../services/session.service';

@Injectable()
export class ProfileService {

  constructor(private http: HttpClient, private sessionService: SessionService) {}

  getProfiles(token) {
    const headers = new HttpHeaders({'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization' : 'Bearer ' + token});

    return this.http.get(environment.connexusAPI + '/securities/profiles', {headers});
  }


  getProfileById(token, profileId) {
    const headers = new HttpHeaders({'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization' : 'Bearer ' + token});

    return this.http.get(environment.connexusAPI + '/securities/profiles/' + profileId, {headers});
  }

  getProfileRoles(token, profileId) {
    const headers = new HttpHeaders({'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization' : 'Bearer ' + token});

    return this.http.get(environment.connexusAPI + '/securities/profiles/' + profileId + '/roles', {headers});
  }

  addProfileRole(token, profileId, roleId) {
    const headers = new HttpHeaders({'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization' : 'Bearer ' + token});

    return this.http.put(environment.connexusAPI + '/securities/profiles/' + profileId + '/roles/' + roleId, null, {headers});
  }

  deleteProfileRole(token, profileId, roleId) {
    const headers = new HttpHeaders({'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization' : 'Bearer ' + token});

    return this.http.delete(environment.connexusAPI + '/securities/profiles/' + profileId + '/roles/' + roleId, {headers});
  }
}
