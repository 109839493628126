import { Injectable } from '@angular/core';

@Injectable()
export class TransactionSearchService {
  
  public transactionSearch = {
    dateFrom: null,
    dateTo: null,
    transactionStatus: null
  }  
  
  constructor() {}
}