import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '@environment';
import { SessionService } from '../services/session.service';

export const RecurrenceTypes = {
  ONE_TIME: "OTM",
  RECURRENCE: "REC"
}

@Injectable()
export class RecurrenceTypeService {

  constructor( private http: HttpClient, private sessionService: SessionService ) { }

  getRecurrenceTypes(token) {
    const headers = new HttpHeaders({'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': 'Bearer ' + token});

    return this.http.get(environment.connexusAPI + '/recurrencetypes', {headers});
  }
}
