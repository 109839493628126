import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

// Constants
const SNACK_BAR_CONFIG = {
  duration: 3600,
  durationForever: 0,
  dismissLabel: 'X',
  errorClass: 'snack-bar-error',
  infoClass:'snack-bar-info',
  successClass:'snack-bar-success'
};

@Injectable()
export class SnackBarService {
  constructor(private snackBar: MatSnackBar){}

  openSuccessMsg(msg: string): void {
    this.openMsg(msg, SNACK_BAR_CONFIG.successClass, SNACK_BAR_CONFIG.duration);
  }

  openInfoMsg(msg: string): void {
    this.openMsg(msg, SNACK_BAR_CONFIG.infoClass, SNACK_BAR_CONFIG.duration);
  }

  openErrorMsg(msg: string): void {
    this.openMsg(msg, SNACK_BAR_CONFIG.errorClass, SNACK_BAR_CONFIG.duration);
  }

  openMsg(msg: string, customClass: string, customeDuration?: number) : void {
    let duration = (typeof customeDuration !== 'undefined') ? customeDuration : SNACK_BAR_CONFIG.duration;
    this.snackBar.open(msg, SNACK_BAR_CONFIG.dismissLabel, {
      duration: duration, verticalPosition: 'bottom', horizontalPosition: 'center', panelClass: [customClass]
    });
  }

  closeMsg(): void {
    this.snackBar.dismiss();
  }
}