import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { environment } from '@environment';
import { SessionService } from '../services/session.service';

export const MAX_SHORT_URL_LENGTH = 50;

@Injectable()
export class PluginService {

  constructor(private http: HttpClient, private sessionService: SessionService) { }

  getPlugins(token, companyId) {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': 'Bearer ' + token});

    return this.http.get(environment.connexusAPI + '/plugins?companyId=' + companyId, {headers});
  }

  createPlugin(token, requestBody) {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': 'Bearer ' + token});

    requestBody = JSON.stringify(requestBody);

    return this.http.post(environment.connexusAPI + '/plugins', requestBody, {headers});
  }

  getPluginByPluginId(token, pluginId) {
    const headers = new HttpHeaders({'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': 'Bearer ' + token});

    return this.http.get(environment.connexusAPI + '/plugins/' + pluginId, {headers});
  }

  getPluginWithShortUrlParams(token, pluginId, invoiceNumber, paymentAmount) {
    const headers = new HttpHeaders({'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': 'Bearer ' + token});
    let url = environment.connexusAPI + '/plugins/' + pluginId;

    if(invoiceNumber) {
      url = url + '?invoicenum=' + invoiceNumber;
      if(paymentAmount)
        url = url + '&paymentamt=' + paymentAmount;
    } else if (paymentAmount) {
      url = url + '?paymentamt=' + paymentAmount;
    }

    return this.http.get(url, {headers});
  }

  updatePlugin(token, requestBody, pluginId) {
    const headers = new HttpHeaders({'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': 'Bearer ' + token});

    requestBody = JSON.stringify(requestBody);

    return this.http.put(environment.connexusAPI + '/plugins/' + pluginId, requestBody, {headers});
  }

  getPluginConfigOptionsByPluginId(token, pluginId) {
    const headers = new HttpHeaders({'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': 'Bearer ' + token});

    return this.http.get(environment.connexusAPI + '/plugins/' + pluginId + '/pluginconfigoptions', {headers});
  }

  deletePlugin(token, pluginId){
    const headers = new HttpHeaders({'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': 'Bearer ' + token});

    return this.http.delete(environment.connexusAPI + '/plugins/' + pluginId, {headers});
  }

  generateCodeSnippet(isAchAuthorizationType: boolean, pluginKey: string, configOptions: any): string {
    let snippet = '<link rel="stylesheet" type="text/css" href="' + environment.rootUrl + '/assets/cxs.css">';

    if (isAchAuthorizationType) {
      snippet += '<form action="' + environment.rootUrl + environment.achAuthPath + '/?key='+ pluginKey +'">';
      snippet += '<div class="cxs-btn"><button id="cxs-btn-default" type="submit"><span id="cxs-span"><strong>REGISTER';
    } else {
      snippet += '<form action="' + environment.connexusAPI + '/bridge" method="post">';
      let amount;
      configOptions.forEach(option => {
        if (option.bridgePluginConfigOptionResponse != null) {
          if (option.bridgePluginConfigOptionResponse.name == 'Amount') {
            amount = option.value;
          }
        } else {
          if (option.name == 'Amount') {
            amount = option.value;
          }
        }
      });
      amount = (amount) ? amount : '1.00';

      snippet += '<input type="hidden" name="amount" value='+parseFloat(amount)+'>';
      snippet += '<input type="hidden" name="pluginKey" value='+ pluginKey +'>';
      snippet += '<input type="hidden" name="merchantTrackingNumber" value="test">';
      snippet += '<div class="cxs-btn"><button id="cxs-btn-default" type="submit"><span id="cxs-span"><strong>PAY WITH';
    }

    snippet += '</strong></span><img src="' + environment.rootUrl + '/assets/images/cxs-button.png" width="170" height="40" border="0"></button></div></form>';

    let div = document.createElement('div');
    div.innerHTML = snippet.trim();

    return this.format(div, 0).innerHTML;
  }

  format(node, level): any {
    var indentBefore = new Array(level++ + 1).join('  '),
      indentAfter = new Array(level - 1).join('  '),
      textNode;

    for (var i = 0; i < node.children.length; i++) {
      textNode = document.createTextNode('\n' + indentBefore);
      node.insertBefore(textNode, node.children[i]);

      this.format(node.children[i], level);

      if (node.lastElementChild == node.children[i]) {
        textNode = document.createTextNode('\n' + indentAfter);
        node.appendChild(textNode);
      }
    }

    return node;
  }
}
