import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { environment } from '@environment';
import { SessionService } from '../services/session.service';

@Injectable()
export class CompanyAccountService {

  constructor( private http: HttpClient, private sessionService: SessionService ) { }

  getCompanyAccounts(token, companyId) {
    const headers = new HttpHeaders({'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': 'Bearer ' + token});

     return this.http.get(environment.connexusAPI + '/companies/' + companyId + '/companyaccounts', {headers});
  }
}
