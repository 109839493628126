// Angular Imports
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { LoggedInGuard } from '@app/logged-in.guard';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { SimpleTimer } from 'ng2-simple-timer';
import { NgxMaskModule, IConfig } from 'ngx-mask';

// Bootstrap
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgBootstrapAlertModule } from 'ng-bootstrap-alert';

// Components
import { AppComponent } from './app.component';
import { SessionModalComponent } from './account-access/modals/session/session-modal.component';
import { DeleteConfirmationComponent } from './account-access/modals/delete-confirmation/delete-confirmation.component';
import { CancelConfirmationComponent } from './account-access/modals/cancel-confirmation/cancel-confirmation.component';

// Environement
import { environment } from '@environment';

import { createErrorHandler } from '@sentry/angular';

// Services
import { CommonsService, ConfigService, SessionService, SessionStorageService, UserService } from './services';

import { ConnexusHttpInterceptor } from '@app/interceptors';

export const options: Partial<IConfig> | (() => Partial<IConfig>) = {};

@NgModule( {
  declarations: [
    AppComponent,
    SessionModalComponent,
    DeleteConfirmationComponent,
    CancelConfirmationComponent,
  ],
  imports: [
    AppRoutingModule,
    BrowserModule,
    HttpClientModule,
    NgbModule,
    NgBootstrapAlertModule,
    NgIdleKeepaliveModule.forRoot(),
    NgxMaskModule.forRoot()
  ],
  providers: [
    CommonsService,
    ConfigService,
    LoggedInGuard,
    SessionService,
    SessionStorageService,
    SimpleTimer,
    UserService,
    { provide: ErrorHandler, useValue: createErrorHandler({ logErrors: !environment.production }) },
    { provide: HTTP_INTERCEPTORS, useClass: ConnexusHttpInterceptor, multi: true, deps: [SessionService] }
  ],
  bootstrap: [AppComponent]
} )
export class AppModule { }
