import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { environment } from '@environment';
import { SessionService } from '../services/session.service';

@Injectable()
export class TransactionBatchService {

  constructor(private http: HttpClient, private sessionService: SessionService) {}

  createAuthHeader(token) {
    return new HttpHeaders({'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization' : 'Bearer ' + token});
  }

  createTransactionBatch(token, data) {
    let headers = this.createAuthHeader(token);

    return this.http.post(environment.connexusAPI + '/transactions/batches', data, {headers});
  }

  getTransactionBatches(token, from, to, companyId?, companyAccountId?, status?) {
    let headers = this.createAuthHeader(token);

    let urlSuffix = '/transactions/batches?date-from=' + from + '&date-to=' + to;

    if (companyId)
      urlSuffix += '&company-id=' + companyId;

    if (companyAccountId)
      urlSuffix += '&company-account-id=' + companyAccountId;

    if (status)
      urlSuffix += '&status=' + status;

    return this.http.get(environment.connexusAPI + urlSuffix, {headers});
  }

  getTransactionBatch(token, batchId) {
    let headers = this.createAuthHeader(token);

    return this.http.get(environment.connexusAPI + '/transactions/batches/' + batchId, {headers});
  }

  updateTransactionBatch(token, data) {
    let headers = this.createAuthHeader(token);

    return this.http.put(environment.connexusAPI + '/transactions/batches/'+ data.id, data, {headers});
  }



}
