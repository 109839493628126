import { SessionService } from '@app/services';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

export class ConnexusHttpInterceptor implements HttpInterceptor {

  constructor(
    private sessionService: SessionService
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const isAuthorizedRequest = !!request.headers.keys().find((key: string) => {
      return key === 'Authorization';
    });
    return next.handle(request).pipe(
      tap(event => {
        if (event instanceof HttpResponse && isAuthorizedRequest && !request.url.includes('dashboardTimeout')) {
          this.sessionService.resetJwtToken(event.headers);
        }
      })
    );
  }

}
