import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { environment } from '@environment';
import { SessionService } from '../services/session.service';

@Injectable()
export class TransactionCommentService {

  constructor(private http: HttpClient, private sessionService: SessionService) {}

  getCommentsByTransactionId(transactionId, token) {
    const headers = new HttpHeaders({'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization' : 'Bearer ' + token});

    return this.http.get(environment.connexusAPI + '/transactions/' + transactionId + '/comments', {headers});
  }

  createCommentByTransactionId(transactionId, data, token) {
    const headers = new HttpHeaders({'Accept': 'application/json', 'Authorization' : 'Bearer ' + token});

    return this.http.post(environment.connexusAPI + '/transactions/'+ transactionId +'/comments', data, {headers});
  }
}
