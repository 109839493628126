import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

import { init } from '@sentry/angular';

init({
  dsn: 'https://ebb29ab1e04b4fbbbc36ad2de910b954@sentry.io/235610',
  environment: environment.envName
});

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule, { preserveWhitespaces: true })
  .catch(err => console.error(err));
