import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { environment } from '@environment';
import { SessionService } from '../services/session.service';

export const PluginTypes = {
  TRANSACTION: 'Transaction',
  ONBOARDING: 'On Boarding',
  ACH_AUTHORIZATION: 'ACH Authorization'
}

@Injectable()
export class PluginTypeService {

  constructor( private http: HttpClient, private sessionService: SessionService ) { }

  getPluginTypes(token, companyId) {
    const headers = new HttpHeaders({'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': 'Bearer ' + token});

    return this.http.get(environment.connexusAPI + '/plugintypes?companyid=' + companyId, {headers});
  }
}
