import { tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '@environment';
import { SessionService } from './session.service';
import { SessionStorageService } from './session-storage.service';

@Injectable()
export class MenuService {

  public menuItems = new Array();

  constructor(
    private http: HttpClient,
    private sessionService: SessionService,
    private sessionStorageService: SessionStorageService
  ) {}

  getMenu(token) {
    const headers = new HttpHeaders({'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization' : 'Bearer ' + token});

    return this.http.get(environment.connexusAPI + '/menuitems', {headers}).pipe(
      tap((res) => {
        this.loadMenuItems(res);
      }));
  }

  getDashboardPanels(token) {
    const headers = new HttpHeaders({'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization' : 'Bearer ' + token});

    return this.http.get(environment.connexusAPI + '/dashboardpanels', {headers});
  }

  loadMenuItems(menuData) {
    let index, menuItem;
    for(index in menuData) {
      if(menuData[index].subMenuItems.length > 0) {
        let index2;
        for(index2 in menuData[index].subMenuItems) {
          menuItem = {'routeString' : menuData[index].subMenuItems[index2].routeString, 'selected' : false };
          this.menuItems.push(menuItem);
        }
      } else {
        menuItem = {'routeString' : menuData[index].routeString, 'selected' : false };
        this.menuItems.push(menuItem);
      }
    }
  }

  setSelected(routeString) {
    let index;
    for(index in this.menuItems) {
      if(routeString == '/account-access/' + this.menuItems[index].routeString) {
        this.menuItems[index].selected = true;
      }else {
        this.menuItems[index].selected = false;
      }
    }
  }

  isSelected(routeString) {
    let index;
    for(index in this.menuItems) {
      if(routeString == this.menuItems[index].routeString && this.menuItems[index].selected) {
        return true;
      }
    }

    return false;
  }
}
