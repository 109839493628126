import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { environment } from '@environment';
import { SessionService } from './session.service';

@Injectable()
export class SignupService {

  constructor(private http: HttpClient, private sessionService: SessionService) {}

  registeration(data, reCaptchaToken) {
    const headers = new HttpHeaders({'Content-Type': 'application/json', 'Accept': 'application/json', 'reCaptchaToken': 'Bearer ' + reCaptchaToken});

    return this.http.post(environment.connexusAPI + '/registrations', data, {headers});
  }

}
