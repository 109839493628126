import { Injectable }  from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '@environment';
import { SessionService } from '../services/session.service';

@Injectable()
export class MetricsService {

  constructor(private http: HttpClient, private sessionService: SessionService) {}

  getTotalSessionStarted(token) {
    const headers = new HttpHeaders({'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization' : 'Bearer ' + token});
    const maxDate = sessionStorage.getItem('maxTransactionDate');

    return this.http.get(environment.connexusAPI + '/metrics/connexsession/totals/totalclickcount?datefrom=2017-01-01&dateto=' + maxDate, {headers});
  }

  getTotalSessionAbandoned(token) {
    const headers = new HttpHeaders({'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization' : 'Bearer ' + token});
    const maxDate = sessionStorage.getItem('maxTransactionDate');

    return this.http.get(environment.connexusAPI + '/metrics/connexsession/totals/abanddonedtxncount?datefrom=2017-01-01&dateto=' + maxDate, {headers});
  }

  getTotalSessionCompleted(token) {
    const headers = new HttpHeaders({'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization' : 'Bearer ' + token});
    const maxDate = sessionStorage.getItem('maxTransactionDate');

    return this.http.get(environment.connexusAPI + '/metrics/connexsession/totals/completedtxncount?datefrom=2017-01-01&dateto=' + maxDate, {headers});
  }


  getTotalSelectBankAbandoned(token) {
    const headers = new HttpHeaders({'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization' : 'Bearer ' + token});
    const maxDate = sessionStorage.getItem('maxTransactionDate');

    return this.http.get(environment.connexusAPI + '/metrics/connexsession/totals/pagelocation/selectbank?datefrom=2017-01-01&dateto='+ maxDate, {headers});
  }


  getIntegratedAbandoned(token) {
    const headers = new HttpHeaders({'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization' : 'Bearer ' + token});
    const maxDate = sessionStorage.getItem('maxTransactionDate');

    return this.http.get(environment.connexusAPI + '/metrics/connexsession/totals/pagelocation/integrated?datefrom=2017-01-01&dateto=' + maxDate, {headers});
  }

  getNonIntegratedAbandoned(token) {
    const headers = new HttpHeaders({'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization' : 'Bearer ' + token});
    const maxDate = sessionStorage.getItem('maxTransactionDate');

    return this.http.get(environment.connexusAPI + '/metrics/connexsession/totals/pagelocation/nontintegrated?datefrom=2017-01-01&dateto=' + maxDate, {headers});
  }

  getTransactionTraffic(token) {
    const headers = new HttpHeaders({'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization' : 'Bearer ' + token});

    return this.http.get('https://dev.connexuslab.com/kw-mojo-dhamgunde-0.1.0/metrics/transaction/traffic', {headers});
  }

  getCompanyIntegrationMetrics(token, companyId, fromDate, toDate) {
    const headers = new HttpHeaders({'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization' : 'Bearer ' + token});
    const urlSuffix = '/metrics/bridge-session-integrations/' + companyId +'?date-from=' + fromDate + '&date-to=' + toDate;

    return this.http.get(environment.connexusAPI + urlSuffix , { headers });
  }

  getTransactionStatusCounts(token, fromDate, toDate, companyId, companyAccountId) {
    const headers = new HttpHeaders({'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization' : 'Bearer ' + token});
    let urlSuffix = '/metrics/transactions/status-counts?date-from=' + fromDate + '&date-to=' + toDate;

    if (companyId)
      urlSuffix += '&company-id='+companyId;

    if (companyAccountId)
      urlSuffix += '&company-account-id='+companyAccountId;

    return this.http.get(environment.connexusAPI + urlSuffix , { headers });
  }

  getTransactionAmountTotals(token, showBy, fromDate, toDate, companyId, companyAccountId, status) {
    const headers = new HttpHeaders({'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization' : 'Bearer ' + token});
    let urlSuffix = '/metrics/transactions/amount-totals?show-by=' + showBy + '&date-from=' + fromDate + '&date-to=' + toDate;

    if (companyId)
      urlSuffix += '&company-id='+companyId;

    if (companyAccountId)
      urlSuffix += '&company-account-id='+companyAccountId;

    if (status)
        urlSuffix += '&status='+status;

    return this.http.get(environment.connexusAPI + urlSuffix , { headers });
  }

  getFinancialInstitutionVerificationCounts(token, showBy, fromDate, toDate, companyId, companyAccountId, financialInstitutionId) {
    const headers = new HttpHeaders({'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization' : 'Bearer ' + token});
    let urlSuffix = '/metrics/transactions/financial-institution-verification-counts?date-from=' + fromDate + '&date-to=' + toDate + '&limit=20';

    if (companyId)
      urlSuffix += '&company-id=' + companyId;

    if (companyAccountId)
      urlSuffix += '&company-account-id=' + companyAccountId;

    if (financialInstitutionId)
      urlSuffix += '&financial-institution-id=' + financialInstitutionId;

    return this.http.get(environment.connexusAPI + urlSuffix , { headers });
  }
}
