
import { tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router} from '@angular/router';

import { environment } from '@environment';
import { SessionService } from '../services/session.service';

import { JwtHelperService } from '@auth0/angular-jwt';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject } from 'rxjs';
import { LOGOUT_REASONS } from '../shared/data';

@Injectable()
export class UserService {
  public loggedIn = false;
  public loggedInSubject = new BehaviorSubject(false);
  public userPermissions;
  private userId;
  private headers = new HttpHeaders({'Content-Type': 'application/json', 'Accept': 'application/json'});
  private jwtHelper: JwtHelperService = new JwtHelperService();
  private token = '';

  constructor(private http: HttpClient, public router: Router, private sessionService: SessionService, private modalService: NgbModal) {
    const authToken = sessionStorage.getItem('auth_token');
    let userAuthorized;

    if (authToken) {
      let decodedJwt = this.jwtHelper.decodeToken(authToken);
      userAuthorized = decodedJwt['jwtType'];
    }

    this.loggedIn = !!userAuthorized;
  }

  login(userName, password) {
    let body = JSON.stringify({userName, password});

    return this.http.post(environment.connexusAPI + '/login', body, {headers: this.headers, observe: 'response'}).pipe(
      tap((res) => {
        const authorizationToken = res.headers.get('Authorization');
        const jwtToken = authorizationToken.substring(7);
        sessionStorage.setItem('auth_token', jwtToken);
        this.loggedIn = true;
        this.loggedInSubject.next(true);
        sessionStorage.setItem('userName', userName);

        let decodedJwt = this.jwtHelper.decodeToken(jwtToken);
        this.userId = decodedJwt.userId;
        this.token = jwtToken;

        this.getUserPermissionsByUserId(this.userId, jwtToken).subscribe((result: any) => {
          if (result) {
            this.userPermissions = result;
          }
        });
    }));
  }

  sendPasswordResetEmail(emailAddress, reCaptchaToken) {
    const headers = new HttpHeaders({'Content-Type': 'application/json', 'Accept': 'application/json', 'reCaptchaToken': 'Bearer ' + reCaptchaToken});
    let body = {'type': 'passwordReset', 'msgType': 'email', 'userName': emailAddress};

    return this.http.post(environment.connexusAPI + '/userverifications', body, {headers});
  }

  resetPassword(userId, newPassword, token) {
    const headers = new HttpHeaders({'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': 'Bearer ' + token});
    let body = JSON.stringify({newPassword});

    return this.http.put(environment.connexusAPI + '/users/' + userId + '/password', body, {headers});
  }

  sendForgotPassword(userId, token) {
    const headers = new HttpHeaders({'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': 'Bearer ' + token});

    return this.http.post(environment.connexusAPI + '/users/' + userId + '/forgot-password', {}, {headers});
  }

  updatePassword(userId: number, body: any, token: string) {
    const headers = new HttpHeaders({'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': 'Bearer ' + token});

    return this.http.put(environment.connexusAPI + '/users/' + userId + '/password', body, {headers});

 }

  moveRegistrationToUser(email, userName, token) {
    const headers = new HttpHeaders({'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': 'Bearer ' + token});
    let body = JSON.stringify({email, userName});

    return this.http.put(environment.connexusAPI + '/registrations/' + userName, body, {headers});
  }

  //need reason to be passed in (UserOption, Expired, etc.)
  logout(reason) {
    sessionStorage.removeItem('auth_token');
    sessionStorage.removeItem('userName');
    this.sessionService.subscribeTimer();
    this.sessionService.isTimeroutSet = false;
    this.loggedIn = false;
    this.loggedInSubject.next(false);
    this.userPermissions = null;
    this.modalService.dismissAll();
    switch (reason) {
      case '401':
        this.router.navigate(['/auth/login'], {queryParams: {'reason': LOGOUT_REASONS.SESSION_INVALID}});
        break;
      case '408':
        this.router.navigate(['/auth/login'], {queryParams: {'reason': LOGOUT_REASONS.SESSION_EXPIRED}});
        break;
      default:
        this.router.navigate(['/auth/login'], {queryParams: {'reason': LOGOUT_REASONS.USER_LOGGED_OUT}});
        break;
   }
 }

  isLoggedIn() {
    if (this.loggedIn) {
      this.token = sessionStorage.getItem('auth_token');
      let decodedJwt = this.jwtHelper.decodeToken(this.token);
      this.userId = decodedJwt.userId;
      this.getUserPermissionsByUserId(this.userId, this.token).subscribe((result: any) => {
        if (result) {
          this.userPermissions = result;
        }
      });
    }

    return this.loggedIn;
  }

  getUsers(token) {
    const headers = new HttpHeaders({'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': 'Bearer ' + token});

    return this.http.get(environment.connexusAPI + '/users', {headers});
  }

  getUser(token: string, userId: number) {
    const headers = new HttpHeaders({'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': 'Bearer ' + token});

    return this.http.get(`${environment.connexusAPI}/users/${userId}`, {headers});
  }

  createUser(user, token) {
    const headers = new HttpHeaders({'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': 'Bearer ' + token});
    const body = JSON.stringify(user);

    return this.http.post(environment.connexusAPI + '/users', body, {headers});
  }

  updateUser(user, token) {
    const headers = new HttpHeaders({'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': 'Bearer ' + token});
    const body = JSON.stringify(user);

    return this.http.put(environment.connexusAPI + '/users/' + user.id, body, {headers});
  }

  getUserPermissionsByUserId(userId, token) {
    const headers = new HttpHeaders({'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': 'Bearer ' + token});

    return this.http.get(environment.connexusAPI + '/securities/users/' + userId + '/permissions', {headers}).pipe(
      tap((res) => {
        this.userPermissions = res;
    }));
  }

  hasPermissions(permissionName) {
    if (this.userPermissions) {
      for (var i = 0; i < this.userPermissions.length; i++) {
        if (this.userPermissions[i].permissionName == permissionName) {
          return true;
        }
      }
    }
    return false;
  }

  resendVerificationEmail(emailAddress, reCaptchaToken) {
    const headers = new HttpHeaders({'Content-Type': 'application/json', 'Accept': 'application/json', 'reCaptchaToken': 'Bearer ' + reCaptchaToken});
    let body = {'type': 'verifySignUp', 'msgType': 'email', 'userName': emailAddress};

    return this.http.post(environment.connexusAPI + '/userverifications/', body, {headers});
 }

  hasScopeLevelPermissions(permissionName, scopeName) {
    if (this.userPermissions) {
      for (var i = 0; i < this.userPermissions.length; i++) {
        if (this.userPermissions[i].permissionName == permissionName && this.userPermissions[i].scopeName == scopeName) {
          return true;
        }
      }
    }
    return false;
  }

  getUserPreferences(token) {
    const headers = new HttpHeaders({'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': 'Bearer ' + token});

    return this.http.get(environment.connexusAPI + '/users/' + this.userId + '/preferences', {headers});
 }

  updateUserPreference(token, preference) {
    const headers = new HttpHeaders({'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': 'Bearer ' + token});

    return this.http.put(environment.connexusAPI + '/users/' + this.userId + '/preferences/' + preference.id, preference, {headers});
  }

  updateUserProfile(token, userId, profileId) {
    const headers = new HttpHeaders({'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': 'Bearer ' + token});

    return this.http.put(environment.connexusAPI + '/users/' + userId + '/profiles/' + profileId, {}, {headers});
  }
}
