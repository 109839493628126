import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { environment } from '@environment';
import { SessionService } from '@services/session.service';

@Injectable()
export class AuditReportService {

  constructor(private http: HttpClient, private sessionService: SessionService) {}

  getTransactions(token, dateFrom, dateTo, status, companyId, companyAccount) {
    const headers = new HttpHeaders({'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization' : 'Bearer ' + token});

    let urlSuffix = '/audits/transactions?datefrom=' + dateFrom + '&dateto=' + dateTo;
    if(companyId){
      urlSuffix += '&companyid=' + companyId;
    }

    if(status)
      urlSuffix = urlSuffix + '&status=' + status;

    if(companyAccount)
    	urlSuffix = urlSuffix + '&companyaccountid=' + companyAccount;

    return this.http.get(environment.connexusAPI + urlSuffix, {headers});
  }

  getTransaction(token, transactionId) {
    const headers = new HttpHeaders({'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization' : 'Bearer ' + token});

    return this.http.get(environment.connexusAPI + '/audits/transactions/' + transactionId , {headers});
  }

  getTransactionStatuses(token) {
    const headers = new HttpHeaders({'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization' : 'Bearer ' + token});

    return this.http.get(environment.connexusAPI + '/transactionstatuses', {headers});
  }
}
