// Angular Imports
import { Component, Renderer2 } from "@angular/core";

// Environment
import { environment } from "@environment";

@Component({
  selector: 'vt-app',
  templateUrl: 'app.component.html'
})

export class AppComponent {
  title = environment.envName;
  environmentName = environment.envName;

  constructor() {}

}
