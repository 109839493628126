export const environment = {
  production: true,
  envName: 'prod',
  smartyStreetsKey: "25801836858348360",
  reCaptchaKey: "6Ld1HkAiAAAAAPXRxm5lXUWVAqXy39jdxEiu9xVv",
  connexusAPI: "https://api.summitpay.fi/apiv1",
  connexusWebScoket: "https://api.summitpay.fi/apiv1",
  appName: "/apiv1",
  awsFiImages: "https://static.summitpay.fi/financial-institutions/",
  stompClientDebug: true,
  achAuthPath: '/ach-authorization',
  rootUrl: 'https://summitpay.fi',
};