import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { environment } from '@environment';
import { SessionService } from '../services/session.service';

@Injectable()
export class CustomerService {

  constructor(private http: HttpClient, private sessionService: SessionService) { }

  getCustomers(token, companyId) {
    const headers = new HttpHeaders({'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': 'Bearer ' + token});
    const Url = environment.connexusAPI + '/companies/' + companyId + '/customers';

    let timeStamp = +new Date();
    const uniqueUrl = Url + '?tsp=' + timeStamp;

    return this.http.get(uniqueUrl, {headers});
  }

  createCustomer(token, body, companyId) {
    const headers = new HttpHeaders({'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': 'Bearer ' + token});

    return this.http.post(environment.connexusAPI + '/companies/' + companyId + '/customers', body, {headers});
  }

  getCustomer(token, companyId, customerId) {
    const headers = new HttpHeaders({'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': 'Bearer ' + token});
    const Url = environment.connexusAPI + '/companies/' + companyId + '/customers/' + customerId;

    let timeStamp = +new Date();
    const uniqueUrl = Url + '?tsp=' + timeStamp;

    return this.http.get(uniqueUrl, {headers});
  }

  updateCustomer(token, companyId, customerId, body) {
    const headers = new HttpHeaders({'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': 'Bearer ' + token});

    return this.http.put(environment.connexusAPI + '/companies/' + companyId + '/customers/' + customerId, body, {headers});
  }

  deleteCustomer(token, companyId, customerId){
    const headers = new HttpHeaders({'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': 'Bearer ' + token});

    return this.http.delete(environment.connexusAPI + '/companies/'+companyId + '/customers/' + customerId, {headers});
  }

  getCustomersByLookup(companyId, lookUp, token) {
    const headers = new HttpHeaders({'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization' : 'Bearer ' + token});
    let requestUrl = environment.connexusAPI + '/companies/' + companyId;

    if(lookUp)
      requestUrl = requestUrl + '/customers?like=' + lookUp;

    return this.http.get(requestUrl, { headers });
  }

}
