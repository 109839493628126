import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SimpleTimer } from 'ng2-simple-timer';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { SessionModalComponent } from '../account-access/modals/session/session-modal.component';
import { environment } from '@environment';
import { ConfigService } from '../services/config.service';
import { CommonsService } from '../services/commons.service';
import { map } from 'rxjs/operators';

@Injectable()
export class SessionService {

  private counter = 0;
  timerId: string;
  private timerButton = 'Subscribe';
  public modalOption: NgbModalOptions = {'windowClass': 'session-timeout-modal', 'backdrop': 'static', 'keyboard': false, 'size': 'sm' };
  private isLoggedIn: boolean = false;
  private timeoutLimit;
  private oneMinute = 60;
  private sessionWarningSec = 30;
  public isTimeroutSet: boolean = false;
  private timeoutConfigOption = 'dashboardTimeout';

  constructor(
    private st: SimpleTimer,
    private http: HttpClient,
    private modalService: NgbModal,
    private configService: ConfigService,
    private commonsService:CommonsService
  ) { }

  initializeTimer() {
    this.st.newTimer('1sec', 1);
    if (this.timerId) {
      // Unsubscribe if timer Id is defined
      this.st.unsubscribe(this.timerId);
      this.timerId = undefined;
      this.timerButton = 'Subscribe';
      this.counter = 0;
    }
    this.subscribeTimer();
  }

  subscribeTimer() {
    if (this.timerId) {
      // Unsubscribe if timer Id is defined
      this.st.unsubscribe(this.timerId);
      this.timerId = undefined;
      this.timerButton = 'Subscribe';
      this.counter = 0;
    } else {
      // Subscribe if timer Id is undefined
      this.timerId = this.st.subscribe('1sec', () => this.timerCallback());
      this.timerButton = 'Unsubscribe';
      this.counter = 0;
    }
  }

  timerCallback() {
    this.counter++;

    if (!this.isTimeroutSet) {
      const token = sessionStorage.getItem('auth_token');
      this.configService.getConfigByName(this.timeoutConfigOption, token).subscribe((result: any) => {
        if (result) {
        this.timeoutLimit = parseInt(result.value);
        this.isTimeroutSet = true;
        }
      });
    }

    if (this.counter == this.oneMinute * this.timeoutLimit - this.sessionWarningSec) {
      this.isLoggedIn = !!sessionStorage.getItem('auth_token');
      if (this.isLoggedIn) {
        this.openSessionModal();
      }
    }
  }

  openSessionModal() {
    const sessionModalRef = this.modalService.open(SessionModalComponent, this.modalOption);
    sessionModalRef.result.then((result: any) => {
      if (result == 'Continue') {
        this.initializeTimer();
      } else {
        if (result == 'timeout') {
          this.subscribeTimer();
          this.isTimeroutSet = false;
          this.commonsService.sessionLogout('408');
        } else {
          this.subscribeTimer();
          this.isTimeroutSet = false;
          this.commonsService.sessionLogout('USER');
        }
      }
    });
  }

  updateJwtToken() {
    const token = sessionStorage.getItem('auth_token');
    const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': 'Bearer ' + token });

    return this.http.get(environment.connexusAPI + '/tokens', { headers, observe: 'response' }).pipe(
      map((res) => {
        const authorizationToken = res.headers.get('Authorization');
        const jwtToken = authorizationToken.substring(7);
        sessionStorage.setItem('auth_token', jwtToken);
        return true;
      }));
  }

  resetJwtToken(responseHeaders: HttpHeaders) {
    const authorizationToken = responseHeaders.get('authorization');
    const jwtToken = authorizationToken.substring(7);
    sessionStorage.setItem('auth_token', jwtToken);
    this.initializeTimer();
  }
}
