import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '@environment';
import { SessionService } from '../services/session.service';

@Injectable()
export class RecurrenceService {

  constructor(private http: HttpClient, private sessionService: SessionService) {}

  getRecurrences(token, companyId, active) {
    const headers = new HttpHeaders({'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': 'Bearer ' + token});

    let urlSuffix = '/transaction-recurrences';

    if (companyId != null && active != null)
	    urlSuffix = urlSuffix + '?active=' + active + '&company-id=' + companyId;
	  else if (companyId != null)
	    urlSuffix = urlSuffix + '?company-id=' + companyId;
    else if (active != null)
	    urlSuffix = urlSuffix + '?active=' + active;

    return this.http.get(environment.connexusAPI + urlSuffix, {headers});
  }

  getRecurrence(token, recurrenceId) {
    const headers = new HttpHeaders({'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': 'Bearer ' + token});

    return this.http.get(environment.connexusAPI + '/transaction-recurrences/' + recurrenceId, {headers});
  }


  updateRecurrence(token, requestBody, recurrenceId) {
    const headers = new HttpHeaders({'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': 'Bearer ' + token })

    requestBody = JSON.stringify(requestBody);

    return this.http.put(environment.connexusAPI + '/transaction-recurrences/' + recurrenceId, requestBody, {headers});
  }

  getRecurrenceTransactions(token, recurrenceId) {
    const headers = new HttpHeaders({'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': 'Bearer ' + token});

    return this.http.get(environment.connexusAPI + '/transaction-recurrences/' + recurrenceId + '/transactions', {headers});
  }
}
