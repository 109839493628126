import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { environment } from '@environment';

@Injectable()
export class ConfigService {

  constructor(private http: HttpClient) {}

  getConfigByName(configName, token){
    const headers = new HttpHeaders({'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization' : 'Bearer ' + token});

    return this.http.get<any>(environment.connexusAPI + '/config?configName='+configName, { headers });
  }
}

