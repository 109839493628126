// logged-in.guard.ts
import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { UserService } from '@services/user.service';

@Injectable()
export class LoggedInGuard implements CanActivate {
    constructor(private user: UserService, public router: Router) {}

    canActivate() {
        if(this.user.isLoggedIn()) { 
            return true; 
        }
        
        this.router.navigate(['/auth'])
        return this.user.isLoggedIn();
    }
}